import axios from 'axios'
import { baseUrl } from './strings'

const instance = axios.create({baseURL: baseUrl + 'api/'})

export function initAWDAuthorization() {
	// axios.defaults.baseURL = process.env.REACT_APP_BACKEND_DOMAIN;
	axios.defaults.baseURL = "http://192.168.1.15:8000";
	axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.common["Name"] = "Bathiku";

    const Source = process.env.REACT_APP_BATHIKU_DOMAIN;
    axios.defaults.headers.common["Source"] = Source.endsWith("/") ? Source.slice(0, -1) : Source;
    // const ApiSource = process.env.REACT_APP_API_BATHIKU_DOMAIN;
    const ApiSource = "http://192.168.1.8:8001";
    axios.defaults.headers.common["ApiSource"] = ApiSource.endsWith("/") ? ApiSource.slice(0, -1) : ApiSource;
}

export function initAuthorization() {
	axios.defaults.baseURL = process.env.REACT_APP_API_BATHIKU_DOMAIN;
    axios.defaults.headers.common["ApiSource"] = "";
}

export default instance